/****************************
 *      BOOTSTRAP CORE      *
 ****************************/

// Overload default font size
$font-size-base: 0.9rem;

// Overload default colors
$primary: #a8c34c;
$secondary: #8f9289;
$dark: #67723c;
/* $light: #d6e893; */

.bg-info {
  background-color: var(--dark) !important;
}

.border-info {
  border-color: var(--dark) !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: var(--dark) !important;
}

a.text-info:focus,
a.text-info:hover {
  color: var(--dark) !important;
}

a.text-info-active:focus,
a.text-info-active:hover {
  color: white !important;
}

.dropdown-menu {
  @extend .dropdown-menu;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
}

/****************************
 *          TABLES          *
 ****************************/
.table-striped tbody tr:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

/****************************
 *          MODALS          *
 ****************************/
.modal-content {
  -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
  border: 1px solid #ffffff;
}

.modal-header {
  background-color: var(--light);
}

.modal-backdrop {
  display: none;
}

.modal {
  background: rgba(0, 0, 0, 0.5);
}

/****************************
 *          FORMS           *
 ****************************/
.form-label {
  margin-bottom: 0;
}

legend {
  font-size: 1.1em !important;
}

label.required .fa-asterisk {
  font-size: 0.6em;
  position: absolute;
  font-weight: bold;
}

/****************************
 *    CURSORS / POINTERS    *
 ****************************/

// Set "zoom-in" cursor to selected element
.cursor-zoom-in {
  cursor: zoom-in;
}

// Set hand cursor to selected element
.cursor-pointer {
  cursor: pointer;
}

// Disables all click events on and in the selected element
.pointer-events-none {
  pointer-events: none;
}

/****************************
 *          BUTTONS         *
 ****************************/

// Avoid "hover animation" on buttons with "light" variant
.btn-light.nohover {
  @extend .bg-light;
  @extend .border-light;
}

/****************************
 *        TEXT FORMAT       *
 ****************************/

// Justify text
.text-justify {
  text-align: justify;
}

// Remove redundant white spaces AND justify text
.text-white-space {
  @extend .text-justify;
  white-space: pre-line;
}

/****************************
 *    AUTO SUGGEST INPUT    *
 ****************************/
.react-autosuggest__input {
  @extend .form-control;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow-y: auto;
  max-height: 30em;
}

.react-autosuggest__suggestion {
  @extend .cursor-pointer;
}

.react-autosuggest__suggestions-container--open {
  @extend .container;
  @extend .p-0;
  @extend .position-absolute;
  @extend .z-1;
  @extend .row;
  @extend .shadow-sm;
  @extend .border-start;
  @extend .border-end;
  @extend .border-bottom;
  @extend .border-white;
  @extend .rounded-bottom;
  @extend .bg-light;
  max-width: 42em !important;
}

.react-autosuggest__suggestions-container--open .row:hover {
  @extend .bg-white;
}

/****************************
*        NAVIGATION         *
****************************/
.nav-link.disabled,
.nav-link:disabled {
  color: #cdcdcd !important;
}

/****************************
*        ANIMATIONS         *
****************************/
// Allow elements to be animated, like a zoom-in. There are three levels of zooming (extra-small, small, medium, high). Default means medium.
.zoomable,
.zoomable-xs,
.zoomable-sm,
.zoomable-md,
.zoomable-xl {
  transition: transform 0.3s ease;
}

.zoomable-xs:hover {
  transform: scale(1.1);
}

.zoomable-sm:hover {
  transform: scale(1.2);
}

.zoomable-md:hover {
  transform: scale(1.5);
}

.zoomable-xl:hover {
  transform: scale(2);
}

// Default zoomable
.zoomable:hover {
  @extend .zoomable-md;
}

/****************************
 *        PAGINATOR         *
 ****************************/
// Remove unwanted margin in this component
.pagination > ul {
  margin-bottom: 0;
}

/****************************
 *           MISC           *
 ****************************/

// Custom color to display some elements (mainly bootstrap cards) as selected
.bg-success-selected {
  @extend .bg-success;
  @extend .bg-opacity-50;
}

// Custom color to display some elements (mainly bootstrap cards) as selected
.bg-warning-selected {
  @extend .bg-warning;
  @extend .bg-opacity-10;
}

// Custom color to display some elements (mainly bootstrap cards) as selected
.bg-info-light {
  @extend .bg-info;
  @extend .bg-opacity-10;
}

// Filtered results are search fields. When filter is applied, we set a border color to make it obvious.
.filtered-result,
.filtered-result .react-select__control {
  @extend .border-info !optional;
  @extend .bg-info-light;
}

// Custom color to highlight some accordions items
.accordion-selected {
  @extend .text-success;
  background-color: #f3fff3 !important;
}

// Manage custom form labels layout
.custom-label {
  @extend .form-label;
  margin-bottom: 0 !important;
}

.custom-label::after {
  content: ":";
}

// Display Home Cards as big links with hover color
.HomeItem {
  @extend .link-underline;
  @extend .link-underline-opacity-0;
}

.HomeItem:hover .card {
  @extend .bg-warning-selected;
}

/****************************
 *          IMPORTS         *
 ****************************/
// Importer Bootstrap SCSS directement (NOTA : Toujours importer APRES avoir modifié les styles)
@import "~bootstrap/scss/bootstrap";
