.tab-content {
  padding: 1em;
  border: 1px solid var(--dark);
  border-width: 1px 1px 1px 1px;
  box-sizing: border-box;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: linear-gradient(180deg, #fafafa 50%, #f5f5f5);
}

.nav-tabs {
  border-bottom-color: var(--dark) !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: whitesmoke;
  font-weight: 400;
  border-color: var(--dark) !important;
  background: var(--dark) !important;
  color: white !important;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.nav-link:hover {
  color: var(--dark) !important;
  background-color: #f1f8c4;
  border: 1px solid var(--dark) !important;
  border-radius: 0.2em;
}

.nav-link.selected {
  color: rgba($color: #000000, $alpha: 0.7) !important;
  background-color: var(--menu-border-color);
  border: 1px solid var(--menu-border-color-hover) !important;
  border-radius: 0.2em;
  box-shadow: inset 0 0 0.2em rgba($color: #000000, $alpha: 0.5);
  cursor: default;
}

.nav-link:not(.disabled),
.nav-link:not(.active) {
  color: var(--dark);
}

#mainMenuContainer {
  border-bottom: 1px solid white;
  box-shadow: 0px 1px 10px rgba($color: #000000, $alpha: 0.3);
}

#mainMenuContainer .navbar {
  background-color: white;
  border-bottom: 3px solid var(--primary);
}

#mainMenuContainer .nav-link {
  border: 1px solid transparent;
  white-space: nowrap;
}
