.changelog {
  max-height: 35em;
  overflow-y: auto;
}

.changelog H4 {
  padding-bottom: 0.5em;
  border-bottom: 1px solid #eee;
}

.jira-issue {
  color: #999;
  font-size: 0.8em;
  padding-left: 0.5em;
}

.jira-issue:after {
  content: "]";
}

.jira-issue:before {
  content: "[E4MAD-";
}

.jira-issue.e4bizz:before {
  content: "[E4BIZZ-";
}

.jira-issue.pharma:before {
  content: "[PM-";
}
