/* FILE EXPLORER DOCUMENTS DB */

.folder-icon .fa-folder-closed {
  color: #8e8e8e;
}

.folder-icon .fa-folder-open {
  color: #627323;
}

.sidebar-content,
.main-content {
  overflow-y: auto;
  max-height: calc(100vh - 10em);
  min-height: 70vh;
  background-color: #ffffff;
  border: 1px solid gainsboro;
  -webkit-border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
}

.sidebar-content {
  margin-right: 0.5em;
}

.file-manager-item-folder .item-content {
  cursor: pointer;
}

.file-manager-item-folder .item-content-active {
  font-weight: bold;
  color: #627323;
  cursor: pointer;
}

.main-file-content-item {
  border: 1px solid transparent;
  -webkit-border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
}

.main-file-content-item,
.main-file-content-item-active {
  height: 10em;
  width: 10em;
  cursor: pointer;
}

.main-file-content-item-active {
  background-color: lightyellow;
  border: 1px solid darkorange;
  -webkit-border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
}

.main-file-content-item:hover {
  background-color: lightcyan;
  border: 1px solid turquoise;
}

#main-file-icon {
  color: #8e8e8e;
  font-size: 4em;
}

.main-file-content-item:hover > #main-file-icon {
  color: darkturquoise;
}

.main-file-content-item-active > #main-file-icon {
  color: darkorange;
}

.file-explorer {
  user-select: none;
  /* standard syntax */
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
}
